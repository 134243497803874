<template>
  <a-table
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
      selections: selections,
      hideDefaultSelections: true,
    }"
    :scroll="{ x: '100%', y: 'calc(100vh - 240px)' }"
    :columns="columns"
    :pagination="pagination"
    :data-source="listData"
    :custom-row="customRow"
    class="device_list_table"
    v-if="listData.length > 0"
  >
    <template #Operate>
      <span class="row row_distribute">分配</span>
    </template>
  </a-table>
  <div class="device_list_empty" v-else>
    <div class="empty_con">
      <div class="empty_icon"></div>
      <div class="empty_tip">暂无可分配设备</div>
    </div>
  </div>
  <a-modal
    v-model:visible="distributeModelShow"
    title="分配设备"
    class="rays-tuner-device-edit-modal"
    :width="400"
    :footer="null"
  >
    <a-form layout="vertical" :hideRequiredMark="true">
      <a-form-item class="user" v-bind="validateInfos.user" label="用户">
        <a-select
          v-model:value="formState.user"
          placeholder="请选择"
          size="large"
          :options="userOptions"
        >
        </a-select>
      </a-form-item>
      <a-form-item class="modal_btn submit_btn">
        <a-button @click="onSubmit" :loading="submiting" size="large"
          >保存</a-button
        >
      </a-form-item>
      <a-form-item class="modal_btn cancel_btn">
        <a-button @click="onCancel" size="large">取消</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  reactive,
  ref,
} from 'vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { Form, message } from 'ant-design-vue';

const useForm = Form.useForm;

const columns = [
  {
    title: '序列号',
    dataIndex: 'pin',
  },
  {
    title: '手机型号',
    dataIndex: 'ptype',
    width: '24%',
  },
  {
    title: '分配用户',
    dataIndex: 'user',
    width: '29%',
  },
  {
    title: '操作',
    dataIndex: 'operate',
    width: '16%',
    slots: { customRender: 'Operate' },
  },
];
export default defineComponent({
  name: 'DeviceList',
  props: {},
  setup() {
    let deviceListDOM = null;
    let deviceListPs = null;

    const distributeModelShow = ref(false);

    const selectedRowKeys = ref([]);
    const selectAll = ref(false);
    const selections = [
      {
        key: 'all',
        text: '全选所有',
        onSelect: () => {
          const _keys = [];
          for (let i = 0; i < listData.value.length; i++) {
            _keys.push(listData.value[i].key);
          }
          selectedRowKeys.value = _keys;
          selectAll.value = true;
        },
      },
      {
        key: 'page',
        text: '全选当前页',
        onSelect: () => {
          const _keys = [];
          for (let i = 0; i < listData.value.length; i++) {
            _keys.push(listData.value[i].key);
          }
          selectedRowKeys.value = _keys;
        },
      },
    ];
    const pagination = reactive({
      size: 'small',
      showQuickJumper: true,
      pageSize: 12,
      total: 0,
      current: 1,
      onChange: (current) => {
        console.log('pagination onChange', current, pagination);
        // this.paginationProps.current = current;
        // if (this.timer) {
        //     clearTimeout(this.timer);
        // }
        // this.getList();
      },
    });
    const listData = ref([]);
    for (let i = 0; i < 40; i++) {
      listData.value.push({
        key: i,
        pin: `NUS899NNK6542H2${i}`,
        ptype: `HUAWEI-TL0${i}`,
        user: `user${i}@a.com`,
      });
    }
    const selectRow = (record) => {
      const selected = [...selectedRowKeys.value];
      if (selected.indexOf(record.key) >= 0) {
        selected.splice(selected.indexOf(record.key), 1);
      } else {
        selected.push(record.key);
      }
      selectedRowKeys.value = selected;
    };
    const customRow = (record) => {
      return {
        onClick: (event) => {
          // console.log('customRow click',record, event,selectedRowKeys.value);
          const _css = event.target.className || '';
          if (_css.includes('row_distribute')) {
            formState.user = record.user;
            distributeModelShow.value = true;
          } else {
            selectRow(record);
          }
        },
      };
    };

    const submiting = ref(false);
    const userOptions = ref([]);
    for (let i = 0; i < 100; i++) {
      userOptions.value.push({
        value: 'user' + i + '@a.com',
        label: 'user' + i + '@a.com',
      });
    }
    const formState = reactive({
      user: undefined,
    });
    const rulesRef = reactive({
      user: [
        {
          required: true,
          message: '输入的手机型号不能为空',
          trigger: 'change',
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      formState,
      rulesRef
    );

    const onSubmit = () => {
      message.success('分配设备成功！');
    };
    const onCancel = () => {
      distributeModelShow.value = false;
    };

    const search = ref('');
    const getList = () => {
      console.log('DeviceList getList', search.value);
    };

    const searchList = (newsearch) => {
      search.value = newsearch || '';
      getList();
    };

    const onSelectChange = (selectedKeys) => {
      console.log('onSelectChange', selectedKeys);
      if (selectedKeys.length < listData.value.length) {
        selectAll.value = false;
      }
      selectedRowKeys.value = selectedKeys;
    };

    onMounted(() => {
      deviceListDOM = document.querySelector(
        '.seat_list_table .ant-table-body'
      );
      if (deviceListDOM) {
        deviceListPs = new PerfectScrollbar(deviceListDOM);
      }
    });
    onBeforeUnmount(() => {
      if (deviceListPs) {
        deviceListPs.destroy();
        deviceListPs = null;
      }
      deviceListDOM = null;
    });
    getList();
    return {
      columns,
      pagination,
      listData,
      selectedRowKeys,
      selections,
      userOptions,
      formState,
      submiting,
      distributeModelShow,
      validateInfos,
      resetFields,
      onCancel,
      onSubmit,
      searchList,
      validate,
      customRow,
      onSelectChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.device_list_table {
  padding-left: 40px;
  padding-right: 40px;
  span.row {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #604bdc;
    cursor: pointer;
  }
  span.row_distribute {
    &:hover {
      color: #7967e0;
    }
  }
}
.device_list_empty {
  position: relative;
  width: 100%;
  height: 100%;
  .empty_con {
    position: absolute;
    left: calc(50% - 90px);
    top: calc(50% - 80px);
    width: 160px;
    height: 182px;
  }
  .empty_icon {
    width: 79px;
    height: 79px;
    margin-left: 40px;
    background: url('~@/assets/Inbox.png') no-repeat;
    border: 1px dashed rgba(0, 0, 0, 0.2);
  }
  .empty_tip {
    margin-top: 8px;
    color: #666;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: CENTER;
  }
  .empty_upload {
    width: 160px;
    height: 48px;
    margin-top: 24px;
    color: #fff;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 500;
    background: #604bdc;
    border-color: #604bdc;
    border-radius: 4px;
  }
}
.rays-tuner-device-edit-modal {
  .modal_btn button {
    width: 320px;
    height: 48px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
  }
  .ant-input {
    &:hover {
      border-color: #604bdc;
    }
    &:focus {
      border-color: #604bdc;
      box-shadow: 0 0 0 2px rgba(96, 75, 220, 0.2);
    }
  }
  .submit_btn button {
    color: #fff;
    background-color: #604bdc;
    border-color: #604bdc;
    &:hover,
    &:focus,
    &:active {
      background-color: #7967e0;
      border-color: #7967e0;
    }
  }
  .cancel_btn button {
    background-color: #fafafa;
    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
      border-color: #d9d9d9;
      color: #3c3c3c;
    }
  }
}
</style>
<style lang="scss">
.device_list_table {
  .ant-table-header {
    background: #fff;
  }
  .ant-table-thead > tr > th {
    color: #aaa;
    font-size: 12px;
    background: #fff;
  }
  .ant-table-row {
  }
}
.rays-tuner-device-edit-modal {
  .ant-modal-close .ant-modal-close-x {
    width: 72px;
    height: 72px;
    .anticon {
      vertical-align: -0.5em;
    }
  }
  .ant-modal-header {
    height: 72px;
    padding: 24px 24px 24px 40px;
    border-bottom: 0;
  }
  .ant-modal-body {
    padding: 8px 40px 8px 40px;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #604bdc;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #604bdc;
    box-shadow: 0 0 0 2px rgba(96, 75, 220, 0.2);
  }
}
</style>
