<template>
  <div class="device_manage_header">
    <div class="header_title">设备管理</div>
    <div class="split"></div>
    <a-tabs v-model:activeKey="deviceManageInfo.tabKey" @tabClick="tabClick">
      <a-tab-pane key="1" tab="Seat分配"> </a-tab-pane>
      <a-tab-pane key="2" tab="设备分配"> </a-tab-pane>
    </a-tabs>
    <div class="header_tool">
      <a-button
        v-if="deviceManageInfo.tabKey == '1'"
        class="tool_del"
        @click="clickDel"
      >
        批量删除
      </a-button>
      <a-button
        type="primary"
        v-if="deviceManageInfo.tabKey == '1'"
        class="tool_seat"
        @click="clickSeat"
      >
        分配 Seat
      </a-button>
      <a-input
        placeholder="序列号/手机型号"
        v-model:value="search"
        class="device_search"
        @press-enter="pressEhter"
        v-if="deviceManageInfo.tabKey == '2'"
      >
        <template #prefix>
          <svg-icon icon-class="search" class="top_icon" />
        </template>
      </a-input>
    </div>
  </div>
  <div class="device_manage_body">
    <seat-list
      v-if="deviceManageInfo.tabKey == '1'"
      v-model:showSetSeat="showSeat"
      ref="seatlist"
    />
    <device-list v-if="deviceManageInfo.tabKey == '2'" ref="devicelist" />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import SeatList from './SeatList';
import DeviceList from './DeviceList';
import { setDeviceManageInfo } from '@/models/tunerStorage';
import store from '@/vuex/store';

export default defineComponent({
  name: 'DeviceManage',
  components: {
    SeatList,
    DeviceList,
  },
  setup() {
    const search = ref('');
    const seatlist = ref(null);
    const devicelist = ref(null);
    const showSeat = ref(false);
    const deviceManageInfo = computed(
      () => store.state.enterprise.deviceManageInfo || {}
    );
    console.log('deviceManageInfo.value', deviceManageInfo.value);

    const tabClick = (val) => {
      // console.log('tabClick', val);
      deviceManageInfo.value.tabKey = val;
      setDeviceManageInfo(deviceManageInfo.value);
    };

    const clickDel = () => {
      seatlist.value.delConfirm();
    };

    const clickSeat = () => {
      // console.log('showSeat', showSeat.value);
      showSeat.value = true;
    };

    const pressEhter = () => {
      // console.log('pressEhter',search.value);
      devicelist.value.searchList(search.value);
    };

    onMounted(() => {
      deviceManageInfo.value.tabKey = deviceManageInfo.value.tabKey || '1';
      setDeviceManageInfo(deviceManageInfo.value);
    });
    return {
      deviceManageInfo,
      showSeat,
      search,
      seatlist,
      devicelist,
      tabClick,
      clickDel,
      clickSeat,
      pressEhter,
    };
  },
});
</script>

<style lang="scss" scoped>
.device_manage_header {
  height: 72px;
  padding: 20px 40px 20px 40px;
  border-bottom: 1px solid #d8d8d8;
  background-color: #fff;
  .top_icon {
    width: 16px;
    height: 16px;
    color: #aaa;
  }
  .header_title {
    width: 64px;
    height: 24px;
    display: inline-block;
    vertical-align: 24px;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222;
    line-height: 24px;
  }
  .split {
    height: 16px;
    width: 0%;
    display: inline-block;
    margin-left: 16px;
    vertical-align: 22px;
    border-right: 1px solid #d8d8d8;
  }
  .ant-tabs-top {
    width: 210px;
    display: inline-block;
    margin-top: -10px;
    margin-left: 16px;
    vertical-align: 10px;
  }
  .header_tool {
    width: 320px;
    height: 32px;
    float: right;
    .tool_del {
      margin-left: 115px;
      &:focus,
      &:hover,
      &:active {
        color: #ff2222;
        border-color: #ff2222;
      }
    }
    .tool_seat {
      margin-left: 24px;
      background-color: #604bdc;
      border-color: #604bdc;
      color: #fff;
      &:focus,
      &:hover,
      &:active {
        background-color: #7a67e4;
        border-color: #7a67e4;
      }
    }
    .device_search {
      &.ant-input-affix-wrapper-focused {
        border-color: #604bdc;
        box-shadow: 0 0 0 2px rgba(96, 75, 220, 0.2);
      }
      &:focus {
        border-color: #604bdc;
        box-shadow: 0 0 0 2px rgba(96, 75, 220, 0.2);
      }
      &:hover {
        border-color: #604bdc;
      }
    }
  }
}
.device_manage_body {
  height: calc(100% - 72px);
  background-color: #fff;
}
</style>
<style lang="scss">
.device_manage_header {
  .ant-tabs-bar {
    margin: 0;
    border-color: #fff;
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab {
    color: #8c8c8c;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #222;
  }
  .ant-tabs-ink-bar {
    background-color: #604bdc;
  }

  .ant-tabs-nav-wrap {
    text-align: center;
  }
}
</style>
